/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export class OneHttpParams extends HttpParams {
  constructor(public silenceAction: boolean, public language: string = null) {
    super();
  }

  override append(param: string, value: string): OneHttpParams {
    const result = super.append(param, value) as OneHttpParams;
    result.silenceAction = this.silenceAction;
    result.language = this.language;

    return result;
  }
}

export interface IRequestOptions {
  headers?: HttpHeaders;
  observe?: 'body' | 'events' | 'response';
  params?: OneHttpParams;
  reportProgress?: boolean;
  responseType?: 'blob' | 'json' | 'text' | 'arraybuffer';
  withCredentials?: boolean;
  body?: any;
}

export function OneHttpClientCreator(http: HttpClient): OneHttpClient {
  return new OneHttpClient(http);
}

@Injectable({
  providedIn: 'root',
})
export class OneHttpClient {
  // Extending the HttpClient through the Angular DI.
  public constructor(public httpBase: HttpClient) {
    // If you don't want to use the extended versions in some cases you can access the public property and use the original one.
    // for ex. this.httpClient.http.get(...)
  }

  public get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return this.httpBase.get<T>(endPoint, this.getOptions(options)) as any;
  }

  public post<T>(endPoint: string, params: any, options?: IRequestOptions): Observable<T> {
    return this.httpBase.post<T>(endPoint, params, this.getOptions(options)) as any;
  }

  public put<T>(endPoint: string, params: any, options?: IRequestOptions): Observable<T> {
    return this.httpBase.put<T>(endPoint, params, this.getOptions(options)) as any;
  }

  public delete<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return this.httpBase.delete<T>(endPoint, this.getOptions(options)) as any;
  }

  private getOptions(options: IRequestOptions): any {
    // to modify options  for all methods
    return options;
  }
}
